import Vue from 'vue';
import Vuex from 'vuex';
import landlordStore from '@/store/landlord';
import renterStore from '@/store/renter';
import { AuthRole } from '@/types/application/Auth/AuthRole';
import { IAlert } from '@/types/application/Alert/IAlert';
import { IAuthCheckResponse } from '@/types/application/Auth/IAuthCheckResponse';
import Alert, { AlertType } from '@/types/application/Alert/Alert';
import axios from 'axios';

Vue.use(Vuex);

const api = axios.create({
    baseURL: '/api/',
    timeout: 60000,
});

const store = new Vuex.Store({
    state: {
        authChecked: false as boolean,
        auth: {
            userId: null,
            authenticated: false,
            isImpersonating: false,
            role: null as null | AuthRole,
            fullName: null as null | string,
            email: null as null | string,
            phone: null as null | string,
        } as IAuthCheckResponse,
        timezone: null as null | string,
        alerts: [] as IAlert[],
        email: null as null | string,
        keys: {
            stripePublicKey: process.env.VUE_APP_STRIPE_PUBLIC_KEY,
        },
    },
    getters: {
        authChecked: (state): boolean => state.authChecked,
        isAuthenticated: (state): boolean => state.auth.authenticated,
        role: (state): null | AuthRole => state.auth.role,
        email: (state): null | string => state.auth.email,
        auth: (state): IAuthCheckResponse => state.auth,
    },
    mutations: {
        PUSH_ALERT(state, alert: IAlert) {
            state.alerts.push(alert);

            if (state.alerts.length > 3) {
                state.alerts.pop();
            }

            window.scrollTo(0, 0); // reset scroll
        },
        CLEAR_ALERTS(state) {
            state.alerts = state.alerts.filter((a) => a.persistent);
        },
        SET_AUTHENTICATION(state, auth: IAuthCheckResponse) {
            state.authChecked = true;
            state.auth = auth;
        },
        SET_EMAIL(state, email: string) {
            state.email = email;
        },
        SET_TIMEZONE(state, timeZoneName: string) {
            state.timezone = timeZoneName;
        },
    },
    actions: {
        async CHECK_AUTH({ commit }) {
            try {
                const response = await api.get<IAuthCheckResponse>('/auth/check');
                const auth = response.data as IAuthCheckResponse;
                commit('SET_AUTHENTICATION', auth);
            } catch (e) {
                if (e.response.status === 404) {
                    await api.get('auth/logout');
                } else {
                    commit('PUSH_ALERT', new Alert(AlertType.Danger, "Couldn't fetch authentication data"));
                }
            }
        },
    },
    modules: {
        landlord: landlordStore,
        renter: renterStore,
    },
});

export default store;
