import { IAlert } from '@/types/application/Alert/IAlert';

export enum AlertType {
    Success = 'success',
    Danger = 'danger',
    Warning = 'warning',
    Info = 'info'
}

export default class Alert implements IAlert {
    date: number;

    type: string | null;

    content: string | null;

    dismissible: boolean;

    persistent: boolean;

    constructor(type: AlertType, text: string, dismissible?: boolean, persistent?: boolean) {
        this.date = new Date().getUTCMilliseconds();
        this.type = type;
        this.content = text;
        this.dismissible = dismissible ?? true;
        this.persistent = persistent ?? false;
    }
}
