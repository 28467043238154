
import VueBase from '@/mixins/VueBase';
import { IAlert } from '@/types/application/Alert/IAlert';

export default VueBase.extend({
    components: {},
    data() {
        return {};
    },
    computed: {
        alerts(): IAlert[] {
            return this.$store.getters.alerts;
        },
        showFooter(): boolean {
            return !this.$route.matched.some((m) => m.meta.showFooter === false);
        },
    },
    mounted() {
        const externalScript = document.createElement('script');
        externalScript.setAttribute('src', 'https://kit.fontawesome.com/276da4aa90.js');
        document.head.appendChild(externalScript);
    },
    async beforeMount(): Promise<void> {
        this.setAdIdCookie();
        await this.$store.commit('SET_TIMEZONE', Intl.DateTimeFormat().resolvedOptions().timeZone);
    },
    methods: {
        async logout(): Promise<void> {
            await this.$api.get('auth/logout');
            await this.$store.dispatch('CHECK_AUTH');
            this.$router.push({ name: 'landlord_login' });
        },
        setAdIdCookie(): void {
            const params = new URLSearchParams(window.location.search);
            const adId = params.has('gclid') ? params.get('gclid') : null;

            if (adId) {
                const encodedAdId = encodeURIComponent(adId);
                document.cookie = `gclid=${encodedAdId}; path=/; max-age=${60 * 60 * 24 * 365}`; // Expires in 1 year
            }
        },
    },
});
