import { RouteConfig } from 'vue-router';
import { AuthRole } from '@/types/application/Auth/AuthRole';

/* Landlord Views */
const LandlordBase = () => import(/* webpackChunkName: "landlord-initial" */'@/views/LandlordBase.vue');
const LandlordDashboard = () => import(/* webpackChunkName: "landlord-initial" */'@/views/Landlord/LandlordDashboard.vue');
const LandlordLogin = () => import(/* webpackChunkName: "landlord-initial" */'@/views/Landlord/Auth/LandlordLogin.vue');
const SignUp = () => import(/* webpackChunkName: "landlord-initial" */'@/views/Landlord/SignUps/SignUp.vue');

const PREIPodcastAnalysisSignUp = () => import(/* webpackChunkName: "landlord-podcasts" */'@/views/Landlord/SignUps/PREIPodcastAnalysisSignUp.vue');
const RERPodcastAnalysisSignUp = () => import(/* webpackChunkName: "landlord-podcasts" */'@/views/Landlord/SignUps/RERPodcastAnalysisSignUp.vue');
const RIPPodcastAnalysisSignUp = () => import(/* webpackChunkName: "landlord-podcasts" */'@/views/Landlord/SignUps/RIPPodcastAnalysisSignUp.vue');
const HerPodcastAnalysisSignUp = () => import(/* webpackChunkName: "landlord-podcasts" */'@/views/Landlord/SignUps/HERPodcastAnalysisSignUp.vue');

const UserProfile = () => import(/* webpackChunkName: "landlord-base" */'@/views/Shared/Profile/UserProfile.vue');
const IdentityVerification = () => import(/* webpackChunkName: "landlord-base" */'@/components/Shared/Persona/IdentityVerification.vue');
const PaymentMethod = () => import(/* webpackChunkName: "landlord-base" */'@/views/Landlord/Payments/PaymentMethod.vue');
const LandlordInbox = () => import(/* webpackChunkName: "landlord-base" */'@/views//Landlord/Inbox/LandlordInbox.vue');

/* Property Views */
const Property = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/Properties/Detail/Property.vue');
const PropertyListing = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/Properties/Detail/PropertyListing/PropertyListing.vue');
const PropertyLease = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/Properties/Detail/PropertyLease/PropertyLease.vue');
const PropertyLeaseInvoice = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/Properties/Detail/PropertyLease/_components/PropertyLeaseInvoice.vue');

const PropertyMaintenance = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Landlord/Properties/Detail/PropertyMaintenance.vue');
const AddTicket = () => import(/* webpackChunkName: "renter-lease" */ '@/components/Shared/Tickets/AddTicket.vue');
const EditTicket = () => import(/* webpackChunkName: "renter-lease" */ '@/components/Shared/Tickets/EditTicket.vue');

const PropertyProspects = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/Properties/Detail/PropertyProspects/PropertyProspects.vue');
const PropertyActivityList = () => import(/* webpackChunkName: "property-detail" */'@/views/Landlord/PropertyActivities/PropertyActivityList.vue');

const RentalAnalysisCall = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Pages/RentalAnalysisCall.vue');
const PropertySetup = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/PropertySetup.vue');
const PropertyAddress = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyAddress.vue');
const PropertyBuilding = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyBuilding.vue');
const PropertyMarketing = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyMarketing.vue');
const PropertyPhotos = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyPhotos.vue');
const PropertyAmenities = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyAmenities.vue');
const PropertyUtilities = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyUtilities.vue');
const PropertyPricing = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyPricing.vue');
const PropertyTours = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyTours.vue');
const PropertyScheduleCall = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyScheduleCall.vue');
const PropertyVerification = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyVerification.vue');
const PropertyLockbox = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyLockbox.vue');
const PropertyLockboxRegister = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyLockboxRegister.vue');
const PropertyServices = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyServices.vue');
const PropertyCheckout = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Properties/Setup/_children/PropertyCheckout.vue');
const PropertyPaymentStatus = () => import(/* webpackChunkName: "property-setup" */'@/views/Landlord/Payments/PropertyPaymentStatus.vue');

/* Prospect Views */
// const ProspectEdit = () => import(/* webpackChunkName: "property-prospects" */'@/views/Landlord/Prospects/ProspectEdit.vue');
const ProspectView = () => import(/* webpackChunkName: "property-prospects" */'@/views/Landlord/Prospects/ProspectView.vue');

/* Lease Views */
const LeaseAdd = () => import(/* webpackChunkName: "property-lease" */'@/views/Landlord/Leases/LeaseAdd.vue');
const LeaseEdit = () => import(/* webpackChunkName: "property-lease" */'@/views/Landlord/Leases/LeaseEdit.vue');
const LeaseSign = () => import(/* webpackChunkName: "property-lease" */'@/views/Landlord/Leases/LeaseSign.vue');
const AccountStripeDashboard = () => import(/* webpackChunkName: "property-lease" */'@/views/Landlord/_components/Stripe/AccountStripeDashboard.vue');

// const SalesPitch = () => import('@/views/Landlord/SalesPitch.vue');
// const PropertyEditConfirmListing = () => import('@/views/Landlord/Properties/Edit/PropertyEditConfirmListing.vue');
// const PropertyAdditionalServices = () => import('@/views/Landlord/Properties/PropertyAdditionalServices.vue');

const propertyRoutes: Array<RouteConfig> = [
    {
        path: 'properties/:propertyId',
        name: 'property_detail',
        component: Property,
        props: true,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
        },
        children: [
            {
                path: 'listing',
                name: 'property_detail_listing',
                component: PropertyListing,
                props: true,
            },
            {
                path: 'prospects',
                name: 'property_detail_prospects',
                component: PropertyProspects,
                props: true,
            },
            {
                path: 'lease',
                name: 'property_detail_lease',
                component: PropertyLease,
                props: true,
                children: [
                    {
                        path: 'invoices/:invoiceId',
                        name: 'property_detail_lease_invoice',
                        component: PropertyLeaseInvoice,
                        props: true,
                        children: [],
                    }],
            },
            {
                path: 'maintenance',
                name: 'property_maintenance',
                component: PropertyMaintenance,
                props: true,
                children: [
                    {
                        path: 'tickets/add',
                        name: 'property_maintenance_add_ticket',
                        component: AddTicket,
                        props: true,
                    },
                    {
                        path: 'tickets/:ticketHashId',
                        name: 'property_maintenance_edit_ticket',
                        component: EditTicket,
                        props: true,
                    },
                ],
            },
            {
                path: 'activity',
                name: 'property_detail_activity',
                component: PropertyActivityList,
                props: true,
                meta: {
                    showFooter: false,
                },
            },
            {
                path: 'payment-status',
                name: 'property_payment_status',
                component: PropertyPaymentStatus,
                props: true,
            },
        ],
    },
    // Setup
    {
        path: 'properties/setup/new',
        name: 'property_setup_new',
        component: PropertyAddress,
        props: false,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
            showFooter: false,
            bodyClasses: ['mw768', 'm-auto'],
        },
    },
    {
        path: 'properties/:propertyId/setup',
        name: 'property_setup',
        component: PropertySetup,
        props: true,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
            showFooter: false,
            bodyClasses: ['m-auto'],
        },
        redirect: { name: 'property_setup_building' },
        children: [
            {
                path: 'address',
                name: 'property_setup_address',
                component: PropertyAddress,
                props: true,
            },
            {
                path: 'property',
                name: 'property_setup_building',
                component: PropertyBuilding,
                props: true,
            },
            {
                path: 'marketing',
                name: 'property_setup_marketing',
                component: PropertyMarketing,
                props: true,
            },
            {
                path: 'photos',
                name: 'property_setup_photos',
                component: PropertyPhotos,
                props: true,
            },
            {
                path: 'amenities',
                name: 'property_setup_amenities',
                component: PropertyAmenities,
                props: true,
            },
            {
                path: 'utilities',
                name: 'property_setup_utilities',
                component: PropertyUtilities,
                props: true,
            },
            {
                path: 'rent',
                name: 'property_setup_pricing',
                component: PropertyPricing,
                props: true,
            },
            {
                path: 'tours',
                name: 'property_setup_tours',
                component: PropertyTours,
                props: true,
            },
            {
                path: 'advisor-call',
                name: 'property_setup_schedule_call',
                component: PropertyScheduleCall,
                props: true,
            },
            // {
            //     path: 'screening',
            //     name: 'property_setup_screening',
            //     component: PropertyScreening,
            //     props: true,
            // },
            {
                path: 'verification',
                name: 'property_setup_verification',
                component: PropertyVerification,
                props: true,
            },
            {
                path: 'lockbox',
                name: 'property_setup_lockbox',
                component: PropertyLockbox,
                props: true,
            },
            {
                path: 'lockbox/register',
                name: 'property_setup_lockbox_register',
                component: PropertyLockboxRegister,
                props: true,
            },
            {
                path: 'services',
                name: 'property_setup_services',
                component: PropertyServices,
                props: true,
            },
            // {
            //     path: 'checkout',
            //     name: 'property_setup_checkout',
            //     component: PropertyCheckout,
            //     props: true,
            // },
        ],
    },
    // {
    //     path: 'properties/:id/additional-services',
    //     name: 'property_additional_services',
    //     component: () => import('@/views/Landlord/Properties/PropertyAdditionalServices.vue'),
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         role: AuthRole.Landlord,
    //     },
    // },
    // {
    //     path: 'properties/:id/checkout',
    //     name: 'property_checkout',
    //     component: () => import('@/views/Landlord/Properties/PropertyCheckout.vue'),
    //     props: true,
    //     meta: {
    //         requiresAuth: true,
    //         role: AuthRole.Landlord,
    //     },
    // },
    {
        path: 'properties/:propertyId/prospects/:prospectHashId',
        name: 'prospect_view',
        component: ProspectView,
        props: true,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
        },
    },
    {
        path: 'properties/:propertyId/prospects/:prospectHashId/lease',
        name: 'prospect_lease',
        component: LeaseAdd,
        props: true,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
        },
    },
    /*  Inbox */
    {
        path: 'inbox',
        name: 'landlord_inbox',
        component: LandlordInbox,
        props: true,
        meta: {
            requiresAuth: true,
            role: AuthRole.Landlord,
            showFooter: false,
        },
        children: [
            {
                path: 'thread/:propertyId/:prospectHashId',
                name: 'landlord_inbox_thread',
                component: LandlordInbox,
                props: true,
            },
        ],
    },
];

const landlordBasePath = process.env.NODE_ENV === 'production' ? '/' : '/landlord/';

const routes: Array<RouteConfig> = [
    {
        path: landlordBasePath,
        component: LandlordBase,
        redirect: { name: 'landlord_dashboard' },
        children: [
            {
                path: 'login',
                name: 'landlord_login',
                component: LandlordLogin,
            },
            /* Sign ups */
            {
                path: 'signup',
                name: 'landlord_signup',
                component: SignUp,
            },
            {
                path: 'dashboard',
                name: 'landlord_dashboard',
                component: LandlordDashboard,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'profile',
                name: 'landlord_profile',
                component: UserProfile,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'identity',
                name: 'landlord_id_verification',
                component: IdentityVerification,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'payment-method',
                name: 'payment_method',
                component: PaymentMethod,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'stripe-connection',
                name: 'stripe_connection',
                component: AccountStripeDashboard,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'properties/:propertyId/leases/:leaseId/edit',
                name: 'landlord_lease_edit',
                component: LeaseEdit,
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            {
                path: 'properties/:propertyId/leases/:leaseId/sign',
                name: 'landlord_sign_lease',
                component: LeaseSign,
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Landlord,
                },
            },
            ...propertyRoutes,
        ],
    },
    {
        path: `${landlordBasePath}analysis/passive`,
        name: 'landlord_prei_podcast_analysis_signup',
        component: PREIPodcastAnalysisSignUp,
    },
    {
        path: `${landlordBasePath}analysis/rip`,
        name: 'landlord_rip_podcast_analysis_signup',
        component: RIPPodcastAnalysisSignUp,
    },
    {
        path: `${landlordBasePath}analysis/rookie`,
        name: 'landlord_rer_podcast_analysis_signup',
        component: RERPodcastAnalysisSignUp,
    },
    {
        path: `${landlordBasePath}analysis/investher`,
        name: 'landlord_investher_podcast_analysis_signup',
        component: HerPodcastAnalysisSignUp,
    },
    {
        path: `${landlordBasePath}analysis-call`,
        name: 'landlord_analysis_call',
        component: RentalAnalysisCall,
    },
    // {
    //     // will match everything
    //     path: `${landlordBasePath}*`,
    //     redirect: { name: 'landlord_dashboard' },
    //     meta: {
    //         requiresAuth: true,
    //         role: AuthRole.Landlord,
    //     },
    // },
];

export default routes;
