import { RouteConfig } from 'vue-router';
import { AuthRole } from '@/types/application/Auth/AuthRole';

const AdminBase = () => import(/* webpackChunkName: "admin-base" */ '@/views/AdminBase.vue');
const AdminDashboard = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/AdminDashboard.vue');
const AdminLogin = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/AdminLogin.vue');
const UserProfile = () => import(/* webpackChunkName: "admin-base" */ '@/views/Shared/Profile/UserProfile.vue');
const AdminAccountList = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/Accounts/AdminAccountList.vue');
const AdminProperties = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/Properties/List/AdminProperties.vue');
const AdminProspects = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/Prospects/List/AdminProspects.vue');
const AdminWorkOrders = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/WorkOrders/AdminWorkOrders.vue');
const AdminInbox = () => import(/* webpackChunkName: "admin-base" */ '@/views/Admin/AdminInbox.vue');

const AdminPropertyDetail = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/AdminProperty.vue');
const AdminPropertyData = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyData.vue');
const AdminPropertyProspectList = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/Prospects/AdminPropertyProspectList.vue');
const AdminProspect = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Prospects/Detail/AdminProspect.vue');
const AdminProspectInformation = () => import(/* webpackChunkName: "admin-property" */'@/views/Admin/Prospects/Detail/_components/AdminProspectInformation.vue');
const AdminProspectTours = () => import(/* webpackChunkName: "admin-property" */'@/views/Admin/Prospects/Detail/_components/AdminProspectTours.vue');
const AdminProspectMessages = () => import(/* webpackChunkName: "admin-property" */'@/views/Admin/Prospects/Detail/_components/AdminProspectMessages.vue');
const AdminProspectEmails = () => import(/* webpackChunkName: "admin-property" */'@/views/Admin/Prospects/Detail/_components/AdminProspectEmails.vue');
const AdminProspectMember = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Prospects/Detail/AdminProspectMember.vue');
const AdminPropertyLockbox = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyLockbox.vue');
const AdminPropertyLeaseList = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/Leases/AdminPropertyLeaseList.vue');
const AdminPropertyLease = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/Leases/AdminPropertyLease.vue');
const AdminPropertyWorkOrders = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyWorkOrders.vue');
const AdminPropertyReport = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyReport.vue');
const AdminPropertyDocuments = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyDocuments.vue');
const AdminPropertyActivityList = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Activity/AdminPropertyActivityList.vue');
const AdminPropertyAudits = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyAudits.vue');
const AdminPropertyDiffs = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyDiffs.vue');
const AdminPropertyUrls = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyUrls.vue');
const AdminPropertyMessages = () => import(/* webpackChunkName: "admin-property" */ '@/views/Admin/Properties/Detail/_components/AdminPropertyMessages.vue');

const AdminAccountView = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Accounts/Detail/AdminAccount.vue');
const AdminAccountInformation = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Accounts/Detail/_components/AdminAccountInformation.vue');
const AdminAccountProperties = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Accounts/Detail/_components/AdminAccountProperties.vue');
const AdminAccountEmails = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Accounts/Detail/_components/AdminAccountEmails.vue');
const AdminAccountAdd = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Accounts/AdminAccountAdd.vue');
const AdminAuditList = () => import(/* webpackChunkName: "admin-accounts" */ '@/views/Admin/Audits/AdminAuditList.vue');

const routes: Array<RouteConfig> = [
    {
        path: process.env.NODE_ENV === 'production' ? '/' : '/admin',
        name: 'admin',
        component: AdminBase,
        redirect: { name: 'admin_properties' },
        children: [
            {
                path: 'login',
                name: 'admin_login',
                component: AdminLogin,
            },
            {
                path: 'dashboard',
                name: 'admin_dashboard',
                component: AdminDashboard,
                redirect: { name: 'admin_properties' },
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            {
                path: 'profile',
                name: 'admin_profile',
                component: UserProfile,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            // Accounts
            {
                path: 'accounts',
                name: 'admin_accounts',
                component: AdminAccountList,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            {
                path: 'accounts/add',
                name: 'admin_account_add',
                component: AdminAccountAdd,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            {
                path: 'accounts/view/:accountId',
                name: 'admin_account_view',
                component: AdminAccountView,
                redirect: { name: 'admin_account_view_info' },
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
                children: [
                    {
                        path: 'information',
                        name: 'admin_account_view_info',
                        component: AdminAccountInformation,
                        props: true,
                    },
                    {
                        path: 'properties',
                        name: 'admin_account_view_properties',
                        component: AdminAccountProperties,
                        props: true,
                    },
                    {
                        path: 'emails',
                        name: 'admin_account_view_emails',
                        component: AdminAccountEmails,
                        props: true,
                    },
                ],
            },
            // Property Activity
            {
                path: 'properties/activity',
                name: 'admin_properties_activity',
                component: AdminPropertyActivityList,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            // Audits
            {
                path: 'audits',
                name: 'admin_audits',
                component: AdminAuditList,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            // Properties
            {
                path: 'properties',
                name: 'admin_properties',
                component: AdminProperties,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            {
                path: 'properties/:propertyHashId',
                name: 'admin_property_view',
                component: AdminPropertyDetail,
                props: true,
                redirect: { name: 'admin_property_data' },
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
                children: [
                    {
                        path: 'data',
                        name: 'admin_property_data',
                        component: AdminPropertyData,
                    },
                    // Prospects
                    {
                        path: 'prospects/add',
                        name: 'admin_prospect_add',
                        component: AdminProspectInformation,
                        props: true,
                    },
                    {
                        path: 'prospects',
                        name: 'admin_property_prospects',
                        component: AdminPropertyProspectList,
                    },
                    {
                        path: 'prospects/:prospectHashId',
                        name: 'admin_prospect',
                        component: AdminProspect,
                        props: true,
                        redirect: { name: 'admin_prospect_information' },
                        children: [
                            {
                                path: 'information',
                                name: 'admin_prospect_information',
                                component: AdminProspectInformation,
                                props: true,
                            },
                            {
                                path: 'tours',
                                name: 'admin_prospect_tours',
                                component: AdminProspectTours,
                                props: true,
                            },
                            {
                                path: 'messages',
                                name: 'admin_prospect_messages',
                                component: AdminProspectMessages,
                                props: true,
                            },
                            {
                                path: 'emails',
                                name: 'admin_prospect_emails',
                                component: AdminProspectEmails,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'prospects/:prospectHashId/user/:userId',
                        name: 'admin_prospect_user_edit',
                        component: AdminProspectMember,
                        props: true,
                    },
                    {
                        path: 'prospects/:prospectHashId/user/add',
                        name: 'admin_prospect_user_add',
                        component: AdminProspectMember,
                        props: true,
                    },
                    // Leases
                    {
                        path: 'leases',
                        name: 'admin_property_leases',
                        component: AdminPropertyLeaseList,
                        props: true,
                    },
                    {
                        path: 'leases/:leaseId',
                        name: 'admin_property_lease_edit',
                        component: AdminPropertyLease,
                        props: true,
                    },
                    // Lockbox
                    {
                        path: 'lockbox',
                        name: 'admin_property_lockbox',
                        component: AdminPropertyLockbox,
                        props: true,
                    },
                    // Work Orders
                    {
                        path: 'work-orders',
                        name: 'admin_property_work_orders',
                        component: AdminPropertyWorkOrders,
                        props: true,
                    },
                    // Report
                    {
                        path: 'report',
                        name: 'admin_property_report',
                        component: AdminPropertyReport,
                        props: true,
                    },
                    // Documents
                    {
                        path: 'documents',
                        name: 'admin_property_documents',
                        component: AdminPropertyDocuments,
                        props: true,
                    },
                    // Audits
                    {
                        path: 'history',
                        name: 'admin_property_audits',
                        component: AdminPropertyAudits,
                        props: true,
                    },
                    // Diffs
                    {
                        path: 'changes',
                        name: 'admin_property_diffs',
                        component: AdminPropertyDiffs,
                        props: true,
                    },
                    // URLs
                    {
                        path: 'urls',
                        name: 'admin_property_urls',
                        component: AdminPropertyUrls,
                        props: true,
                    },
                    // URLs
                    {
                        path: 'messages',
                        name: 'admin_property_messages',
                        component: AdminPropertyMessages,
                        props: true,
                    },
                ],
            },
            // Prospects
            {
                path: 'prospects',
                name: 'admin_prospects',
                component: AdminProspects,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            // Prospects
            {
                path: 'work-orders',
                name: 'admin_work_orders',
                component: AdminWorkOrders,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                },
            },
            // Inbox
            {
                path: 'inbox',
                name: 'admin_inbox',
                component: AdminInbox,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Admin,
                    showFooter: false,
                },
            },

            //
            // {
            //     // will match everything
            //     path: '*',
            //     redirect: { name: 'admin_dashboard' },
            //     meta: {
            //         requiresAuth: true,
            //         role: AuthRole.Admin,
            //     },
            // },
        ],
    },
];

export default routes;
