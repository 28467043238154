import Alert, { AlertType } from '@/types/application/Alert/Alert';
import axios from 'axios';
import { Module } from 'vuex';
import { IRenterSetupResponse } from '@/types/application/Setup/IRenterSetupResponse';

const api = axios.create({
    baseURL: '/api/',
    timeout: 60000,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renterStore: Module<any, any> = {
    state: {
        setup: { hasObfuscatedEmail: false } as IRenterSetupResponse,
    },
    getters: {
        hasObfuscatedEmail: (state) => state.setup.hasObfuscatedEmail,
    },
    mutations: {
        SET_RENTER_SETUP(state, setup: IRenterSetupResponse) {
            state.setup = setup;
        },
    },
    actions: {
        async FETCH_RENTER_SETUP({ commit }) {
            try {
                const response = await api.get<IRenterSetupResponse>('/setup/renter');
                commit('SET_RENTER_SETUP', response.data);
            } catch (e) {
                commit('PUSH_ALERT', new Alert(AlertType.Danger, "Couldn't fetch setup data"));
            }
        },
    },
};

export default renterStore;
