import AlertMixin from '@/mixins/AlertMixin';
import { AuthRole } from '@/types/application/Auth/AuthRole';

export default AlertMixin.extend({
    props: {},
    computed: {
        isAuthenticated(): boolean {
            return this.$store.state.auth.authenticated
                && this.$store.state.auth.role === AuthRole.Landlord;
        },
    },
});
