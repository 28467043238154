import Vue from 'vue';
import store from '@/store';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { AuthRole } from '@/types/application/Auth/AuthRole';

import adminRoutes from './admin';
import landlordRoutes from './landlord';
import renterRoutes from './renter';

const LandlordBase = () => import(/* webpackChunkName: "public" */'@/views/LandlordBase.vue');
const TermsOfService = () => import(/* webpackChunkName: "public" */'@/views/Landlord/Pages/TermsOfService.vue');
const ListingOverview = () => import(/* webpackChunkName: "public" */'@/views/Public/ListingOverview/ListingOverview.vue');
const TourOverview = () => import(/* webpackChunkName: "public" */'@/views/Public/TourOverview/TourOverview.vue');

Vue.use(VueRouter);

async function checkUserAuth(to: Route, from: Route, next: Function) {
    const authenticatedAsLandlord = store.getters.isAuthenticated && store.getters.role === AuthRole.Landlord;
    const authenticatedAsRenter = store.getters.isAuthenticated && store.getters.role === AuthRole.Renter;
    const authenticatedAsAdmin = store.getters.isAuthenticated && store.getters.role === AuthRole.Admin;

    if (authenticatedAsLandlord && ['root', 'landlord_login'].includes(String(to.name))) {
        next({ name: 'landlord_dashboard' });
    } else if (authenticatedAsRenter && ['root', 'renter_login'].includes(String(to.name))) {
        next({ name: 'renter_dashboard' });
    } else if (authenticatedAsAdmin && ['root', 'admin_login'].includes(String(to.name))) {
        next({ name: 'admin_properties' });
    } else {
        next();
    }
}

const routes: Array<RouteConfig> = [];

if (process.env.NODE_ENV !== 'production') {
    routes.push(
        {
            path: '/',
            component: LandlordBase,
            name: 'root',
            beforeEnter: checkUserAuth,
            children: [],
        },
    );
}

routes.push(
    {
        path: '/terms-of-service',
        name: 'tos',
        component: TermsOfService,
    },
    {
        path: '/listing/:propertyHashId',
        name: 'listing_overview',
        component: ListingOverview,
        meta: {
            requiresAuth: false,
        },
        props: true,
    },
    {
        path: '/tours/:tourHashId',
        name: 'tour_overview',
        component: TourOverview,
        meta: {
            requiresAuth: false,
        },
        props: true,
    },
);

const hostChunks = window.location.host.split('.');
const subdomain = hostChunks.length === 3 ? hostChunks[0] : null;
let allRoutes;

switch (subdomain) {
case 'app':
    allRoutes = routes.concat(landlordRoutes);
    break;
case 'admin':
    allRoutes = routes.concat(adminRoutes);
    break;
case 'rent':
    allRoutes = routes.concat(renterRoutes);
    break;
default:
    allRoutes = routes.concat(landlordRoutes, renterRoutes, adminRoutes);
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: allRoutes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

function clearAlerts() {
    if (store.state.alerts.length > 0) {
        store.commit('CLEAR_ALERTS');
    }
}

function getLoginRouteByRole(role: AuthRole) {
    const routesMap = {
        [AuthRole.Admin]: 'admin_login',
        [AuthRole.Landlord]: 'landlord_login',
        [AuthRole.Renter]: 'renter_login',
    };

    return routesMap[role] ?? null;
}

function getInitialRouteByRole(role: AuthRole) {
    const routesMap = {
        [AuthRole.Admin]: 'admin_dashboard',
        [AuthRole.Landlord]: 'landlord_dashboard',
        [AuthRole.Renter]: 'renter_dashboard',
    };

    return routesMap[role] ?? null;
}

async function checkRouteAuth(to: Route, from: Route, next: NavigationGuardNext) {
    if (!store.getters.authChecked) {
        await store.dispatch('CHECK_AUTH');
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        const authMeta = to.matched.find((record) => record.meta.requiresAuth).meta;
        const { role } = authMeta;

        if (!store.getters.isAuthenticated) {
            const loginRouteName = getLoginRouteByRole(role);

            if (loginRouteName) {
                next({ name: loginRouteName, params: { nextUrl: to.fullPath } });
            } else {
                console.log(`No login route found for role ${role}`);
            }
        } else if (role !== store.getters.role) {
            const initialRouteName = getInitialRouteByRole(role);

            if (initialRouteName) {
                next({ name: initialRouteName });
            } else {
                console.log(`No initial route found for role ${role}`);
            }
        } else {
            next();
        }
    } else {
        next();
    }
}

router.beforeEach(async (to, from, next) => {
    try {
        clearAlerts();
        await checkRouteAuth(to, from, next);
    } catch (e) {
        // if (e.name === 'NavigationDuplicated') {
        //
        // }
    }
});

export default router;
