import Alert, { AlertType } from '@/types/application/Alert/Alert';
import axios from 'axios';
import { ILandlordSetupResponse } from '@/types/application/Setup/ILandlordSetupResponse';
import { Module } from 'vuex';
import AccountType from '@/types/application/Account/AccountType';

const api = axios.create({
    baseURL: '/api/',
    timeout: 60000,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const landlordStore: Module<any, any> = {
    state: {
        setup: {} as ILandlordSetupResponse,
    },
    getters: {
        isPromotionalAccount: (state) => state.setup.accountType === AccountType.Promotional,
        hasCompletedIdentityVerification: (state) => state.setup.hasCompletedIdentityVerification === true,
    },
    mutations: {
        SET_LANDLORD_SETUP(state, setup: ILandlordSetupResponse) {
            state.setup = setup;
        },
    },
    actions: {
        async FETCH_LANDLORD_SETUP({ commit }) {
            try {
                const response = await api.get<ILandlordSetupResponse>('/setup/landlord');
                commit('SET_LANDLORD_SETUP', response.data);
            } catch (e) {
                commit('PUSH_ALERT', new Alert(AlertType.Danger, "Couldn't fetch setup data"));
            }
        },
    },
    modules: {},
};

export default landlordStore;
