import axios from 'axios';
import router from '@/router';
import store from '@/store';
import qs from 'qs';
import Alert, { AlertType } from '@/types/application/Alert/Alert';

const api = axios.create({
    baseURL: '/api/',
    timeout: 60000,
    paramsSerializer: (params) => qs.stringify(params, { skipNulls: true }),
});

api.interceptors.response.use((response) => response,
    async (error) => {
        switch (error.response.status) {
        case 400:
            Object.values(error.response.data.errors as [])
                .forEach((err) => store.commit('PUSH_ALERT', new Alert(AlertType.Danger, err[0])));
            break;
        case 401:
            await router.push({ name: 'landlord_login' });
            break;
        case 404:
            if (typeof error.response.data.detail === 'string') {
                store.commit('PUSH_ALERT', new Alert(AlertType.Danger, error.response.data.detail));
            }
            break;
        case 500:
            if (typeof error.response.data.detail === 'string') {
                store.commit('PUSH_ALERT', new Alert(AlertType.Danger, error.response.data.detail));
            } else {
                store.commit('PUSH_ALERT', new Alert(AlertType.Danger, 'There was an error'));
            }
            break;
        default:
            if (typeof error.response.data.detail === 'string') {
                store.commit('PUSH_ALERT', new Alert(AlertType.Danger, error.response.data.detail));
            } else {
                store.commit('PUSH_ALERT', new Alert(AlertType.Danger, 'There was an error'));
            }
        }

        return Promise.reject(error);
    });

export default api;
