import Vue from 'vue';

import './registerServiceWorker';
import { AxiosInstance } from 'axios';
import { BootstrapVue } from 'bootstrap-vue';

import '@/assets/css/custom.scss';

import api from '@/api';
import router from '@/router';
import store from '@/store';

import App from './App.vue';

Vue.use(BootstrapVue, {
    breakpoints: ['xs', 'sm', 'md', 'lg'],
});

declare module 'vue/types/vue' {
    export interface Vue {
        $api: AxiosInstance;
        $apiBase: AxiosInstance;
        $gtm: unknown[];
    }
}

Vue.prototype.$api = api;

/* Google Tag Manager */

declare global {
    interface Window {
        dataLayer: unknown;
    }
}

Vue.prototype.$gtm = window.dataLayer || [];

// Configure Vue

Vue.config.productionTip = false;

// Initialize Vue

const vue = new Vue(
    {
        router,
        store,
        render: (h) => h(App),
    },
)
    .$mount('#app');

export default vue;
