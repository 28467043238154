import Vue from 'vue';
import Alert, { AlertType } from '@/types/application/Alert/Alert';
import { IAlert } from '@/types/application/Alert/IAlert';

export default Vue.extend({
    data() {
        return {
            ownAlerts: [] as IAlert[],
        };
    },
    methods: {
        ownAlert(type: AlertType, message: string) {
            this.ownAlerts = [] as IAlert[];
            this.ownAlerts.push(new Alert(type, message));
        },
        alert(type: AlertType, message: string) {
            this.$store.commit('PUSH_ALERT', new Alert(type, message));
        },
    },
});
