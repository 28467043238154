import { RouteConfig } from 'vue-router';
import { AuthRole } from '@/types/application/Auth/AuthRole';

const RenterBase = () => import(/* webpackChunkName: "renter-base" */ '@/views/RenterBase.vue');
const RenterLogin = () => import(/* webpackChunkName: "renter-base" */ '@/views/Renter/RenterLogin.vue');
const RenterDashboard = () => import(/* webpackChunkName: "renter-base" */ '@/views/Renter/RenterDashboard.vue');
const UserProfile = () => import(/* webpackChunkName: "renter-base" */ '@/views/Shared/Profile/UserProfile.vue');
const Inbox = () => import(/* webpackChunkName: "renter-base" */ '@/views/Shared/Inbox/Inbox.vue');
const UpdateInfo = () => import(/* webpackChunkName: "renter-base" */ '@/views/Renter/UpdateInfo.vue');

const RenterProperty = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Property/RenterProperty.vue');
const RenterPropertyDetail = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Property/RenterPropertyDetail.vue');
const RenterMaintenance = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Property/RenterMaintenance.vue');
const AddTicket = () => import(/* webpackChunkName: "renter-lease" */ '@/components/Shared/Tickets/AddTicket.vue');
const EditTicket = () => import(/* webpackChunkName: "renter-lease" */ '@/components/Shared/Tickets/EditTicket.vue');
const RenterLease = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Lease/RenterLease.vue');
const RenterLeaseInvoice = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Lease/_components/RenterLeaseInvoice.vue');
// const RenterActivity = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/RenterActivity.vue');
// const RenterLeasePayments = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Lease/RenterLeasePayments.vue');
// const RenterLeaseSetup = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/Lease/RenterLeaseSetup.vue');
// const RenterBankAccount = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/RenterBankAccount.vue');
const RenterSignLease = () => import(/* webpackChunkName: "renter-lease" */ '@/views/Renter/RenterSignLease.vue');

// const NotFound = () => import(/* webpackChunkName: "renter-base" */'@/views/Public/NotFound.vue');

const basePath = process.env.NODE_ENV === 'production' ? '/' : '/renter';

const routes: Array<RouteConfig> = [
    {
        path: basePath,
        component: RenterBase,
        redirect: { name: 'renter_dashboard' },
        children: [
            {
                path: 'login',
                name: 'renter_login',
                component: RenterLogin,
            },
            {
                path: 'dashboard',
                name: 'renter_dashboard',
                component: RenterDashboard,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
            },
            // {
            //     path: 'activity',
            //     name: 'renter_activity',
            //     component: RenterActivity,
            //     meta: {
            //         requiresAuth: true,
            //         role: AuthRole.Renter,
            //     },
            // },
            {
                path: 'property/:prospectHashId',
                name: 'renter_property',
                component: RenterProperty,
                props: true,
                redirect: { name: 'renter_property_detail' },
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
                children: [
                    {
                        path: 'detail',
                        name: 'renter_property_detail',
                        component: RenterPropertyDetail,
                        props: true,
                    },
                    {
                        path: 'lease',
                        name: 'renter_property_lease',
                        component: RenterLease,
                        props: true,
                        children: [
                            {
                                path: 'invoices/:invoiceId',
                                name: 'renter_property_lease_invoice',
                                component: RenterLeaseInvoice,
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'maintenance',
                        name: 'renter_property_maintenance',
                        component: RenterMaintenance,
                        props: true,
                        children: [
                            {
                                path: 'tickets/add',
                                name: 'renter_property_maintenance_add_ticket',
                                component: AddTicket,
                                props: true,
                            },
                            {
                                path: 'tickets/:ticketHashId',
                                name: 'renter_property_maintenance_edit_ticket',
                                component: EditTicket,
                                props: true,
                            },
                        ],
                    },
                ],
            },
            // {
            //     path: 'lease/:leaseId/setup',
            //     name: 'renter_lease_setup',
            //     component: RenterLeaseSetup,
            //     props: true,
            //     meta: {
            //         requiresAuth: true,
            //         role: AuthRole.Renter,
            //     },
            // },
            // {
            //     path: 'lease/:leaseId/bank-account',
            //     name: 'renter_bank_account',
            //     component: RenterBankAccount,
            //     props: true,
            //     meta: {
            //         requiresAuth: true,
            //         role: AuthRole.Renter,
            //     },
            // },
            // {
            //     path: 'lease/:leaseId/payments',
            //     name: 'renter_lease_payments',
            //     component: RenterLeasePayments,
            //     props: true,
            //     meta: {
            //         requiresAuth: true,
            //         role: AuthRole.Renter,
            //     },
            // },
            {
                path: 'profile',
                name: 'renter_profile',
                component: UserProfile,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
            },
            {
                path: 'update-info',
                name: 'renter_update_info',
                component: UpdateInfo,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                    showHeader: false,
                },
            },
            /*  Inbox */
            {
                path: 'inbox',
                name: 'renter_inbox',
                component: Inbox,
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
            },
            {
                path: 'inbox/thread/:propertyId/:prospectId',
                name: 'renter_inbox_thread',
                component: Inbox,
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
            },
            {
                path: 'lease/:leaseId/sign',
                name: 'renter_sign_lease',
                component: RenterSignLease,
                props: true,
                meta: {
                    requiresAuth: true,
                    role: AuthRole.Renter,
                },
            },
            // {
            //     path: '*',
            //     name: 'renter_not_found',
            //     component: NotFound,
            //     meta: {
            //         requiresAuth: false,
            //     },
            //     props: true,
            // },
        ],
    },
];

export default routes;
